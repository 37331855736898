import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Novels from './components/Novels';
import NovelIntroduction from './components/NovelIntroduction';
import ChapterContent from './components/ChapterContent';
import HomePage from './components/HomePage';
import AdminPage from './components/AdminPage';
import Translation from './components/Translation';
import Layout from './components/Layout';
import AI from './components/AI';
import FreeChat from './components/FreeChat';

const MaintenancePage = () => (
  <div style={{ 
    padding: '2rem', 
    textAlign: 'center', 
    maxWidth: '600px', 
    margin: '0 auto' 
  }}>
    <h2>Under Maintenance</h2>
    <p>Our novels section is currently under maintenance and will be back soon.</p>
    <p>If you have any questions, please contact us at:</p>
    <a href="mailto:koalanovels@gmail.com">koalanovels@gmail.com</a>
  </div>
);

const ImagePrompts = () => <div>DALL-E Prompt Generator</div>;

const App = () => {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/admin" element={<AdminPage />} />
        <Route path="/translation" element={<Translation />} />
        <Route path="/ai" element={<AI />} />
        <Route path="/ai/freechat" element={<FreeChat />} />
        <Route path="/ai/imageprompts" element={<ImagePrompts />} />
        <Route path="/novels" element={<MaintenancePage />} />
        <Route path="/novels/:novelId" element={<MaintenancePage />} />
        <Route path="/novels/:novelId/chapter/:chapterId" element={<MaintenancePage />} />
      </Routes>
    </Layout>
  );
};

export default App;